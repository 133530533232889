import { useLocation } from "@remix-run/react";
import * as gtm from "app/utils/gtm";
import { useEffect, useRef } from "react";

export function useGTM(gtmId) {
  const location = useLocation();
  const gtmScriptAdded = useRef(false);

  useEffect(() => {
    function addGtmScript() {
      if (gtmScriptAdded.current || !gtmId) return;

      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", gtmId);

      // GTM noscript for the body as a fallback
      const noscript = document.createElement("noscript");
      const iframe = document.createElement("iframe");
      iframe.src = `https://www.googletagmanager.com/ns.html?id=${gtmId}`;
      iframe.height = "0";
      iframe.width = "0";
      iframe.style.display = "none";
      iframe.style.visibility = "hidden";
      noscript.appendChild(iframe);
      document.body.insertBefore(noscript, document.body.firstChild);

      gtmScriptAdded.current = true;
    }

    addGtmScript();
  }, [gtmId]);

  useEffect(() => {
    if (gtmId?.length && gtmScriptAdded.current) {
      gtm.pageview(location.pathname, gtmId);
    }
  }, [gtmId, location]);
}
