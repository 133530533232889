import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  json,
  useRouteError,
  useRouteLoaderData,
} from "@remix-run/react";
import { register } from "swiper/element/bundle";
import { handleRedirects } from "app/.server/http";
import "animate.css/animate.min.css";
import "app/styles/main.css";
import tailwind from "app/tailwind.css?url";
import "swiper/css";

// eslint-disable-next-line no-restricted-imports
import "../chart.config";

import { getEnvVars } from "app/.server/env";

import { useTranslation } from "react-i18next";
import { useChangeLanguage } from "remix-i18next/react";
import { getLocale } from "app/.server/localizedRoute";
import { localeCookie } from "app/.server/i18next";
import { isPathExcluded } from "app/utils/path";
import config from "app/config/i18n";
import Error404Page from "app/components/404";
import { useGTM } from "app/hooks/useGTM";

register();

export const links = () => [
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css?family=Almarai:300,400,700|Montserrat:100,200,300,400,500,600,700,800,900|Josefin+Slab:400,500,600&display=swap",
  },
  {
    rel: "stylesheet",
    href: tailwind,
  },
  {
    rel: "icon",
    href: "/favicon.png",
    type: "image/png",
  },
];

/**
 * @type {import('@remix-run/node').LoaderFunction}
 */
export const loader = async ({ request, params }) => {
  const envs = getEnvVars();
  const lang = params?.lang;
  const locale = !lang
    ? config.fallbackLng
    : await getLocale({ params, request });

  const headers = {};

  const _url = new URL(request.url);

  if (!isPathExcluded(_url.pathname)) {
    headers["Set-Cookie"] = await localeCookie.serialize(locale);
  }

  handleRedirects(new URL(request.url).pathname);

  return json(
    {
      ENV: envs,
      locale,
    },
    {
      headers,
    }
  );
};

export const handle = {
  i18n: "translation",
};

export function Layout({ children }) {
  const data = useRouteLoaderData("root");
  const { ENV, locale } = data || {};
  useGTM(ENV?.REMIX_PUBLIC_GOOGLE_TAGMANAGER_ID);

  const { i18n } = useTranslation();

  useChangeLanguage(locale);

  return (
    <html lang={locale} dir={i18n.dir()} className="scroll-smooth">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="google-site-verification"
          content={ENV?.REMIX_PUBLIC_GOOGLE_SITE_VERIFICATION}
        />
        <Meta />
        <Links />
      </head>
      <body>
        {children}
        <ScrollRestoration />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.env = ${JSON.stringify(ENV)}`,
          }}
        />
        <Scripts />
      </body>
    </html>
  );
}

function App() {
  return <Outlet />;
}

export default withSentry(App);

export function ErrorBoundary() {
  let error = useRouteError();

  captureRemixErrorBoundaryError(error);

  if (isRouteErrorResponse(error)) {
    return <Error404Page />;
  }
}
